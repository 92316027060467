import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {useIntl} from 'react-intl';
import '../styles/news.css'
import Header from '../subcomponents/Header';
import Footer from '../subcomponents/Footer';
import {client} from '../../client';
import { Link } from 'react-router-dom';
import moment from 'moment-js';
import { Fade } from 'react-reveal';



const News = (props) => {
    const intl = useIntl()
    const object = [{main:intl.formatMessage({id:'header_main'})},{about:intl.formatMessage({id:'header_about'})},{news:intl.formatMessage({id:'header_news'})},{contacts:intl.formatMessage({id:'header_contacts'})}]
    const [news,setNews] = useState([])
    const [loading,setLoading] = useState(true)
    function sort_by_key(array, key){
        return array.sort(function(a, b){
            var x = a[key]; var y = b[key];
            return ((x < y) ? 1 : ((x > y) ? -1 : 0));
        });
    }
    useEffect(()=>{
        client.fetch(`*[_type=="post"]{
            title,
            slug,
            publishedAt,
            mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            }
        }`).then(data=>{
            let result = sort_by_key(data,'publishedAt')
            setNews(data);
            setLoading(false)
        })
    },[])
    const newsCard=(title,published,image,key)=>{
        return(
            <Link className='news-card' key={key} to={`/news/${key}`}>
                <div className='news-card-image'>
                    <img src={image}/>
                </div>
                <div className='news-card-text'>
                    <div className='card-text-text'>
                        {title}
                    </div>
                    <div className='card-text-info'>
                        <div className='big-card-date'>
                            {moment(published).format('DD/MM/YYYY')}
                        </div>
                        <div className='big-card-button'>
                            <button>Читать дальше</button>
                        </div>
                    </div>
                </div>
            </Link>
        )
    }
    const smallNewsCard = (title,published,image,key) =>{
        return(
            <div className='small-news-card' key={key}>
                <Link to={key} className='small-news-card-content'>
                    <div className='small-news-card-image'>
                        <img src={image}/>
                    </div>
                    <div className='small-card-group'>
                        <div className='small-card-text' title={title}>
                                {title}
                        </div>
                        <div className='small-card-info'>
                            <div className='card-date'>
                                {moment(published).format('DD/MM/YYYY')}
                            </div>
                            <div className='card-button'>
                                <button>Читать дальше</button>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        )
    }
    return (
        <div className='news-page'>
            <Header navigation={object}/>
            {loading?
            <div className='loading'>
                <CircularProgress sx={{color:'#2A9F92'}} />
            </div>
            :
            !loading&&<div className='news-first-block'>
                <div className='first-block-gradient'>
                    <Fade up cascade>
                        <div className='first-block-content'>
                            {news.slice(0,2).map((item)=>
                                newsCard(item.title,item.publishedAt,item.mainImage.asset.url,item.slug.current))}
                            {news.slice(2,5).map((item)=>
                                smallNewsCard(item.title,item.publishedAt,item.mainImage.asset.url,item.slug.current))}
                        </div>
                    </Fade>
                </div>
                <div className='main-news-block'>
                    <div className='main-news-block-gradient'>
                        <div className='main-news-block-content'>
                        {news.length>=6&&news.slice(5).map((item)=>
                            smallNewsCard(item.title,item.publishedAt,item.mainImage.asset.url,item.slug.current))}
                        </div>
                    </div>
                </div>
                
                <Footer/>
            </div>
            }
        </div>
    );
}

export default News;