import React, { useState, useCallback, useEffect } from 'react';
import {PhotoSlider } from 'react-photo-view';
import '../styles/about.css'
import cert1 from '../../media/cert1.png'
import cert2 from '../../media/cert2.png'
import cert3 from '../../media/cert3.png'
import cert4 from '../../media/cert4.png'
import cert5 from '../../media/cert5.png'
import cert6 from '../../media/cert6.png'
import chistai from '../../media/chistai.svg'
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ReactPlayer from 'react-player'
import photo1 from '../../media/photo1.png'
import photo2 from '../../media/photo2.png'
import photo3 from '../../media/photo3.png'
import about1 from '../../media/about1.png'
import about2 from '../../media/about2.png'
import about3 from '../../media/about3.png'

import shadow from '../../media/mtmk_shadow.png'
import double from '../../media/double_image.png'
import Header from '../subcomponents/Header';
import fbmimage1 from '../../media/fbm-image.png'
import flg from '../../media/flg.png'
import patent from '../../media/patent.svg'
import dot from '../../media/advdot.svg'

import cert from '../../media/patents.png'
import {FormattedMessage,useIntl} from 'react-intl';
import Footer from '../subcomponents/Footer';
import Adv from '../subcomponents/Adv';

const About = () => {
    const intl = useIntl()
    const [showButton, setShowButton] = useState(false);
    const [visible, setVisible] = useState(false);
    const [index, setIndex] = useState(0);
    const [aboutIndex, setAboutIndex] = useState(0);
    const [aboutVisible,setAboutVisible] = useState(false)
    const images = [
        cert1,cert2,cert3,cert4,cert5,cert6
    ];
    const aboutImages = [about1,about2,about3]
    useEffect(()=>{
        window.scrollTo(0, 0)
        if(document.getElementById('about'))document.getElementById('about').addEventListener("scroll", () => {
            if (document.getElementById('about').scrollTop > 960) {
                setShowButton(true);
            } 
            else {
                setShowButton(false);
            }
        });
    },[])
    const advantage = (icon,text) => {
        return(
            <div className='adv'>
                <div className='adv-dot'>
                    <img src={icon}/>
                </div>
                <div className='advant-text'>
                    {text}
                </div>
            </div>
        )  
    }
    const AboutFunction = (number) =>{
        setAboutIndex(number)
        setAboutVisible(true)
    }
    const object = [{main:intl.formatMessage({id:'header_main'})},{about:intl.formatMessage({id:'header_about'})},{news:intl.formatMessage({id:'header_news'})},{contacts:intl.formatMessage({id:'header_contacts'})}]
    return (
        <div className='about-page' id='about'>
            {showButton && (
                <div className="back-to-top">
                    <a rel="noreferrer" href='#top'><ArrowUpwardOutlinedIcon sx={{fontSize:50,color:'white'}}/></a>
                </div>
            )}
            <Header navigation={object}/>
            <div className='about-first-block'>
                <div className='first-content'>
                    <div className='first-text'>
                        <div className='first-text-title'>
                            <FormattedMessage id = "main_about_title"/>
                        </div>
                        <div className='first-text-text'>
                            <FormattedMessage id = "about_text1"/>
                        </div>
                    </div>
                    <div className='first-image'>
                        <img src={double}/>
                    </div>
                    <div className='first-mtmk'>
                        <img src={shadow}/>
                    </div>
                    <div className='first-mtmk-text'>
                        <div className='first-text-title'>
                            <FormattedMessage id = "main_MTMC"/>
                        </div>
                        <div className='first-text-text'>
                            <FormattedMessage id = "about_text2"/> 
                        </div>
                    </div>
                    <div className='second-mtmk-text'>
                        <div className='first-text-title'>
                            <FormattedMessage id = "main_MTMC"/> 
                        </div>
                        <div className='first-text-text'>
                            <FormattedMessage id = "about_text3"/> 
                        </div>
                    </div>
                </div>
            </div>
            <div className='about-photos'>
                <div className='about-photos-content'>
                    <PhotoSlider
                        images={aboutImages.map((item) => ({ src: item, key: item }))}
                        visible={aboutVisible}
                        onClose={() => setAboutVisible(false)}
                        index={aboutIndex}
                        onIndexChange={setAboutIndex}
                    />
                    <div className='about-photo' onClick={()=>AboutFunction(0)}>
                        <img src={photo1}/>
                    </div>
                    <div className='about-photo' onClick={()=>AboutFunction(1)}>
                        <img src={photo2}/>
                    </div>
                    <div className='about-photo'onClick={()=>AboutFunction(2)}>
                        <img src={photo3}/>
                    </div>
                </div>
            </div>
            <div className='about-fbm'>
                <div className='about-fbm-gradient'>
                    <div className='fbm-content'>
                        <div className='fbm-text'>
                            <div className='first-text-title'>
                                <FormattedMessage id = "about_fbm"/> 
                            </div>
                            <div className='first-text-text'>
                                <FormattedMessage id = "about_text4"/>{document.documentElement.clientWidth > 550?<><br/><br/></>:<br/>}
                                <p><FormattedMessage id = "about_text5"/> <a target='_blank' href='https://ftizisbiomed.ru'>ftizisbiomed.ru</a>.</p>
                            </div>
                        </div>
                        <div className='fbm-image'>
                            <img src={fbmimage1}/>
                        </div>
                        <div className='flg-image'>
                            <img src={flg}/>
                        </div>
                        <div className='service'>
                            <div className='first-text-title'>
                                <FormattedMessage id = "about_service"/>                           
                            </div>
                            <div className='first-text-text'>
                                <FormattedMessage id = "about_text6"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='report'>
                <div className='report-content'>
                    <div className='report-text'>
                        <div className='first-text-title'>
                            <FormattedMessage id = "main_report_title"/>
                        </div>
                        <div className='first-text-text'>
                            <FormattedMessage id = "main_report_text"/>
                        </div>
                    </div>
                    <div className='report-video'>
                        <div className='rounded-video'>
                            <ReactPlayer url='https://www.youtube.com/watch?v=fz5DyBtf_Sw'
                            controls={true}
                            muted={false}
                            className="vid"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='toser-advantages'>
                <div className='toser'>
                    <div className='toser-content'>
                        <div className='toser-title'>
                            <FormattedMessage id = "about_toser_title"/>
                        </div>
                        <div className='toser-text'>
                            <FormattedMessage id = "about_toser_text1"/>
                        </div>
                        <div className='toser-adv-chistai'>
                            <div className='toser-adv'>
                                <Adv icon='dot' text={intl.formatMessage({id:'about_toser_text2'})}/>
                                <Adv icon='dot' text={intl.formatMessage({id:'about_toser_text3'})}/>
                                <Adv icon='dot' text={intl.formatMessage({id:'about_toser_text4'})}/>
                            </div>
                            <div className='toser-chistai'>
                                <img src={chistai}/>
                            </div>
                        </div>
                    </div>
                    <div className='patents-content'>
                        <div className='toser-title'>
                            <FormattedMessage id = "about_patents_title"/>
                        </div>
                        <div className='patents-text'>
                            <FormattedMessage id = "about_patents_text1"/>
                        </div>
                        <div className='patents-list'>
                            <Adv icon='patent' text={intl.formatMessage({id:'about_patents_text2'})}/>
                            <Adv icon='patent' text={intl.formatMessage({id:'about_patents_text3'})}/>
                            <Adv icon='patent' text={intl.formatMessage({id:'about_patents_text4'})}/>
                            <Adv icon='patent' text={intl.formatMessage({id:'about_patents_text5'})}/>
                            <Adv icon='patent' text={intl.formatMessage({id:'about_patents_text6'})}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='patents-images'>
                <PhotoSlider
                    images={images.map((item) => ({ src: item, key: item }))}
                    visible={visible}
                    onClose={() => setVisible(false)}
                    index={index}
                    onIndexChange={setIndex}
                />
                <img src={cert} onClick={ () => setVisible(true)}/>
            </div>
            <Footer/>
        </div>
    );
}

export default About;