import React, { useEffect, useState } from 'react';
import vector from '../../media/vector.svg'
import ftz from '../../media/ftz.svg'
import kamazcomp from '../../media/kamaz.svg'
import tng from '../../media/tng.svg'
import radiology from '../../media/radiology.svg'

const PartnerCard = (props) => {
    const [component,setComponent] = useState()
    useEffect(()=>{
        switch(props.id){
            case '1':
                setComponent(vector)
                break
            case '2':
                setComponent(ftz)
                break
            case '3':
                setComponent(tng)
                break
            case '4':
                setComponent(kamazcomp)
                break
            case '5':
                setComponent(radiology)
                break
        }
    },[])
    return (
        <a className='partner-card' id={'partner'+props.id} href={props.href}>
            <img src={component}/>
        </a>
    );
}

export default PartnerCard;