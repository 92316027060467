import React from 'react';
import moment from 'moment-js';
import '../styles/main.css';
import { Link } from 'react-router-dom';


const MainCard = (props) => {
    return (
        <Link className='card-main' to={`/news/${props.slug}`}>
            <div className='card-main-image'>
                <img src={props.image}/>
            </div>
            <div className='text-date-info'>
                <div className='card-main-text'>
                    {props.title}
                </div>
                <div className='small-card-info'>
                    <div className='card-date'>
                        {moment(props.date).format('DD/MM/YYYY')}
                    </div>
                    <div className='card-button'>
                        <button>{props.read}</button>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default MainCard;