import React, { useEffect, useState } from 'react';
import brain from '../../media/ai.svg'
import lamp from '../../media/lamp.png'
import antena from '../../media/antena.svg'
import list from '../../media/list.png'
import toser from '../../media/toser.svg'
import repare from '../../media/repare.svg'

const Advantage = (props) => {
    const [component,setComponent] = useState()
    useEffect(()=>{
        switch(props.id){
            case '1':
                setComponent(list)
                break
            case '2':
                setComponent(repare)
                break
            case '3':
                setComponent(lamp)
                break
            case '4':
                setComponent(brain)
                break
            case '5':
                setComponent(toser)
                break
            case '6':
                setComponent(antena)
                break
        }
    },[])
    return (
        <div className='adv-gradient' id={'item'+props.id}>
            <div className='advantage-item' >
                <div className='advantage-icon'>
                    <img src={component}/>
                </div> 
                <div className='advantage-text'>
                    {props.text}
                </div>           
            </div>
        </div>
    );
}

export default Advantage;