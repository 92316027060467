import React from 'react';
import nav from '../../media/nav.svg'
import phone from '../../media/phone.svg'
import message from '../../media/message.svg'
import logo from '../../media/logox1.5.png'
import {FormattedMessage,useIntl} from 'react-intl';
import moment from 'moment-js';

import '../styles/footer.css'



export const Footer = () => {
    const intl = useIntl()
    const info =(icon,text) =>{
        return(
            <div className='contacts-info'>
                <div className='contacts-info-icon'>
                    <img src={icon} alt='иконка чего-то'/>
                </div>
                <div className='contacts-info-text'>
                    {text}
                </div>
            </div>
        )
    }
    return (
        <footer className='footer'>
            <div className='footer-content'>
                <div className='footer-contacts'>
                    <div className='footer-contacts-title'>
                        <FormattedMessage id = "footer_contacts"/>
                    </div>
                    <div className='footer-contacts-info'>
                       {info(nav,intl.formatMessage({id:'footer_address'}))}
                       {info(phone,'+7 843 425 55 55')}
                       {info(message,'info@tattelemed.ru')}
                    </div>
                </div>
                <div className='footer-comp'>
                    <div className='comp-logo'>
                        <img src={logo}/>
                    </div>
                    <div className='comp-text'>
                        <div className='comp-text-tattelemed'>
                            © {moment().format("YYYY")} <FormattedMessage id = "footer_ttm"/>
                        </div>
                        <div className='comp-text-tattelemed'>
                            © <FormattedMessage id = "footer_vector"/>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;