import { LOCALES } from "./locales";

export const messages = {
    [LOCALES.ENGLISH]: {
        header_main:'MAIN',
        header_about:'ABOUT COMPANY',
        header_news:'NEWS',
        header_contacts:'CONTACTS',

        main_M:'MOBILE',
        main_T:'TELEMEDICINE',
        main_C:'COMPLEX',
        main_MTMC:'MTMC “KAMA-F-AMI”',
        main_details:'Details',
        main_video:'Watch the video',
        main_m:'Mobile',
        main_tc:'telemedicine complex',
        main_about_title:'About the company',
        main_about_text1:'Mobile Telemedicine Fluorography Complex with Artificial Medical Intelligence (MTMC) "KAMA-F-AMI"',
        main_about_text2:'it allows automated fluorographic studies (the basic element of the project, mammographic and/or cardiographic option), necessary for effective mass screening of the health of the population, including those living in rural and hard-to-reach areas, during medical examinations and preventive examinations.',
        main_report_title:'Report on the joint project of MSU, TatTeleMed and FtizisBioMed',
        main_report_text:'Artificial medical intelligence FtizisBioMed (FBM) produced by LLC «FtizisBioMed» as part of the Mobile Telemedicine Complex (MTMC) produced by LLC «TatTeleMed».',
        main_advantages_title:'Main advantages',
        main_advantages_experience:'Experience in IT, telemedicine and telecommunications',
        main_advantages_obsluzhivanie:'We relieve the medical organization from the non-core load of servicing mobile diagnostic rooms',
        main_advantages_rutina:'Improving the effectiveness of fluorographic screening due to the synergistic effect of the "Doctor + Artificial Intelligence" system',
        main_advantages_reliability:'Reliability is guaranteed by 35 years of experience of the parent company of JSC RС «Vector»',
        main_advantages_uniq:'Similar projects are currently absent in Russia and abroad',
        main_advantages_toser1:'«TatTeleMed» is a resident of  ',
        main_advantages_toser2:'TOSER',
        main_advantages_toser3:' «Chistopol». Thanks to this status, we pay less taxes - you get competitive prices',
        main_news_title:'News',
        main_read:'Read more',
        main_news_allnews:'All news',
        main_materials:'Materials',
        main_partners:'Our Partners',

        about_text1:'The limited Liability Company «Tatarstan Telemedicine Complexes» (TatTeleMed), formed in 2019 as a subsidiary of the Federal Communications Operator JSC «Vector Radio Company», specializes in the production of mobile telemedicine fluorographic complexes with artificial medical intelligence (service «FtizisBioMed») «KAMA-F-AMI».',
        about_text2:'Designed to work on the entire territory of the Russian Federation, and, first of all, in 110 thousand settlements that do not have regular medical care.',
        about_text3:'Allows automated fluorographic studies necessary for effective mass screening of the health of the population, including those living in rural and hard-to-reach areas, during medical examinations and preventive examinations.',
        about_fbm:'«FtizisBioMed»',
        about_text4:'The use of FtizisBioMed products and services accelerates the transition to digital healthcare of the modern world level.',
        about_text5:'The program of automated analysis of radiographs / fluorograms (RG/FLG) developed by LLC «FtizisBioMed» is a system of medical decision-making support (SMDMS), which allows you to automatically assess the likelihood of pathologies on digital radiographs / fluorograms of the chest organs (CO), search, localization and classification of pathological signs. For users who want to get acquainted with the work of the service, a demo version is available on the Internet at ',
        about_service:'Service «FtizisBioMed»',
        about_text6:'Able to distinguish and catalog 12 types of pathologies (infiltration – with partial and total shading, hydrothorax (fluid level) – with partial and total shading, cirrhosis, fibrothorax, atelectasis – with partial and total shading, focus, cavity, pneumothorax, foci (dissemination), pleural changes, calcifications, changes in bones).',
        about_toser_title:'«TatTeleMed» is a resident of TASED',
        about_toser_text1:'Residents of TASED "Chistopol" are provided with the following preferences:',
        about_toser_text2:'the tax rate on corporate income tax to be credited to the federal budget is set at 0% (instead of 2%) for the first five years, starting from the year of receipt of the first profit;',
        about_toser_text3:'reduction of tariffs for insurance contributions to state extra-budgetary funds within 10 years from the date of obtaining the legal status of a resident of the TASED;',
        about_toser_text4:'the possibility of exemption from payment of regional property tax and local land tax is provided.',
        about_patents_title:'Patents and certificates',
        about_patents_text1:'In the process of developing the service, registration documents establishing intellectual property rights were obtained:',
        about_patents_text2:'Certificate of state registration of the computer program 2017615663 «Program for automated analysis of fluorographic images»;',
        about_patents_text3:'Certificate of state registration of the computer program 2018615227 «Program for manual marking of pathologies on fluorographic images»;',
        about_patents_text4:'Certificate of state registration of the computer program 2018615226 «Program for automated analysis of fluorographic images (version 2)»;',
        about_patents_text5:'Patent for invention 2684181 «Method of automated analysis of digital fluorographic images»;',
        about_patents_text6:'Certificate of state registration of the database 2020620858 «Marked results of fluorography».',

        contacts_us:'Contact us',
        contacts_ask:'Ask all your questions by phone or email.',

        footer_contacts:'Contacts',
        footer_address:'422980 Republic of Tatarstan, Chistopol, ul.Karl Marx, 135',
        footer_vector:'Vector Radio Company',
        footer_ttm:'LLC «TatTeleMed»',

        tooltip_fl:'Fluorograph «MDF – Alpha-2K»',
        tooltip_fl_text:`The low–dose digital fluorograph «MDF - Alpha-2K» is designed for conducting mass fluorography of the patient's chest in a standing position in straight and lateral projections, producing cranial images and examining the spine with the minimum possible radiation load.`,
        tooltip_ant:'Antenna homing «SAH-1201» Katran',
        tooltip_ant_text:'The SAH-1201 Katran antenna system is designed to work as part of an Earth satellite communication station operating through geostationary spacecraft, receiving and transmitting signals in the Ku-band with an automatic guidance function.',
        tooltip_gen:'Diesel generator «VEPR ADS 8-230 RY2»',
        tooltip_gen_text:'Diesel generator «VEPR ADS 8-230 RY2» is intended for use as an emergency and main power supply source. Guarantees uninterrupted supply of the facility with electricity for 21 hours without refueling.',

    },
    [LOCALES.RUSSIAN]: {
        header_main:'ГЛАВНАЯ',
        header_about:'О ПРЕДПРИЯТИИ',
        header_news:'НОВОСТИ',
        header_contacts:'КОНТАКТЫ',

        main_M:'МОБИЛЬНЫЙ',
        main_T:'ТЕЛЕМЕДИЦИНСКИЙ',
        main_C:'КОМПЛЕКС',
        main_MTMC:'МТМК «КАМА-Ф-ИМИ»',
        main_details:'Подробнее',
        main_video:'Смотреть видео',
        main_m:'Мобильный',
        main_tc:'телемедицинский комплекс',
        main_about_title:'О предприятии',
        main_about_text1:'Мобильный телемедицинский флюорографический комплекс с искусственным медицинским интеллектом (МТМК) «КАМА-Ф-ИМИ»',
        main_about_text2:'позволяет производить автоматизированные флюорографические исследования (базовый элемент проекта, опция маммографический и/или кардиографический), необходимые для эффективного массового скрининга здоровья населения, в том числе проживающего в сельских и труднодоступных районах, при диспансеризациии профилактических осмотрах.',
        main_report_title:'Репортаж о совместном проекте МГУ, ТатТелеМед и ФтизисБиоМед',
        main_report_text:'Искусственный медицинский интеллект ФтизисБиоМед (ФБМ) производства ООО «ФтизисБиоМед» в составе Мобильного Телемедицинского Комплекса (МТМК) производства ООО «ТатТелеМед».',
        main_advantages_title:'Главные преимущества',
        main_advantages_experience:'Опыт в IT, телемедицине и телекоммуникациях',
        main_advantages_obsluzhivanie:'Избавляем медицинскую организацию от непрофильной нагрузки по обслуживанию передвижных диагностических кабинетов',
        main_advantages_rutina:'Повышение эффективности флюорографического скрининга за счёт синергетического эффекта системы  «Врач + Искусственный Интеллект»',
        main_advantages_reliability:'Надёжность гарантирована 35-летним стажем материнской компании АО РК «Вектор»',
        main_advantages_uniq:'Подобные проекты на настоящий момент отсутствует в России и за рубежом',
        main_advantages_toser1:'«ТатТелеМед» -  резидент ',
        main_advantages_toser2:'ТОСЭР',
        main_advantages_toser3:' «Чистополь». Благодаря этому статусу, мы платим меньше налогов - вы получаете конкурентоспособные цены',
        main_news_title:'Новости',
        main_read:'Читать дальше',
        main_news_allnews:'Все новости',
        main_materials:'Метериалы',
        main_partners:'Наши партнёры',

        about_text1:'Общество с ограниченной ответственностью «Татарстанские Телемедицинские Комплексы» (ТатТелеМед), образованное в 2019 году как дочернее предприятие Федерального оператора связи АО «Радиокомпании «Вектор», специализируется на производстве мобильных телемедицинских флюорографических комплексов с искусственным медицинским интеллектом (сервис «Фтизисбиомед») «КАМА-Ф-ИМИ».',
        about_text2:'Предназначен для работы на всей территории Российской Федерации, и, прежде всего, в 110 тыс. населенных пунктах, не имеющих регулярного медицинского обслуживания.',
        about_text3:'Позволяет производить автоматизированные флюорографические исследования, необходимые для эффективного массового скрининга здоровья населения, в том числе проживающего в сельских и труднодоступных районах, при диспансеризации и профилактических осмотрах.',
        about_fbm:'«ФтизисБиоМед»',
        about_text4:'Применение продуктов и сервисов «ФтизисБиоМед» ускоряет переход к цифровому здравоохранению современного мирового уровня.',
        about_text5:'Разработанная ООО «ФтизисБиоМед» программа автоматизированного анализа рентгенограмм/флюорограмм (РГ/ФЛГ) - это система поддержки принятия врачебных решений (СППВР), которая позволяет автоматически оценивать вероятность наличия патологий на цифровых рентгенограммах/флюорограммах органов грудной клетки (ОГК), осуществлять поиск, локализацию и классификацию патологических признаков. Для пользователей, желающих ознакомиться с работой сервиса, доступна демоверсия в сети интернет по адресу ',
        about_service:'Сервис «ФтизисБиоМед»',
        about_text6:'Способен различать и каталогизировать 12 типов патологий (инфильтрация – при частичном и тотальном затенении, гидроторакс (уровень жидкости) – при частичном и тотальном затенении, цирроз, фиброторакс, ателектаз – при частичном и тотальном затенении, очаг, полость, пневмоторакс, очаги (диссеминация), изменения плевры, кальцинаты, изменения в костях).',
        about_toser_title:'«ТатТелеМед» резидент ТОСЭР',
        about_toser_text1:'Резидентам ТОСЭР «Чистополь» предоставляются следующие преференции:',
        about_toser_text2:'налоговая ставка по налогу на прибыль организаций, подлежащему зачислению в федеральный бюджет, устанавливается в размере 0% (вместо 2%) в течение первых пяти лет, начиная с года получения первой прибыли;',
        about_toser_text3:'снижение тарифов по страховым взносам в государственные внебюджетные фонды в течение 10 лет со дня получения юридического статуса резидента ТОСЭР;',
        about_toser_text4:'предусмотрена возможность освобождения от уплаты регионального налога на имущество и местного земельного налога.',
        about_patents_title:'Патенты и сертификаты',
        about_patents_text1:'В процесссе разработки сервиса были получены регистрационные документы, устанавливающие право интеллектуальной собственности:',
        about_patents_text2:'Свидетельство о государственной регистрации программы для ЭВМ 2017615663 «Программа автоматизированного анализа флюорографических изображений»;',
        about_patents_text3:'Свидетельство о государственной регистрации программы для ЭВМ 2018615227 «Программа для ручной разметки патологий на флюорографических снимках»;',
        about_patents_text4:'Свидетельство о государственной регистрации программы для ЭВМ 2018615226 «Программа автоматизированного анализа флюорографических изображений (версия 2)»;',
        about_patents_text5:'Патент на изобретение 2684181 «Способ автоматизированного анализа ЦИФРОВЫХ флюорографических снимков»;',
        about_patents_text6:'Свидетельство о государственной регистрации базы данных 2020620858 «Размеченные результаты флюорографии».',

        contacts_us:'Свяжитесь с нами',
        contacts_ask:'Задайте все интересующие Вас вопросы по телефону или почте.',

        footer_contacts:'Контакты',
        footer_address:'422980 Республика Татарстан, г.Чистополь, ул.Карла Маркса, д.135',
        footer_vector:'АО РК «Вектор»',
        footer_ttm:'ООО «ТатТелеМед»',

        tooltip_fl:'Флюорограф «ФЦМ – Альфа-2К»',
        tooltip_fl_text:'Флюорограф цифровой малодозовый «ФЦМ – Альфа-2К» предназначен для проведения массовой флюорографии грудной клетки пациента в положении стоя в прямой и боковой проекциях, производства черепных снимков и исследования позвоночника при минимально возможной лучевой нагрузке.',
        tooltip_ant:'Антенная самонаводящаяся «САС-1201» Катран',
        tooltip_ant_text:'Антенная система САС-1201 Катран предназначена для работы в составе земной станции спутниковой связи, работающий через геостационарные космические аппараты, приёма и передачи сигналов в Ku-диапазоне с функцией автоматического наведения.',
        tooltip_gen:'Дизельный генератор «ВЕПРЬ АДС 8-230 РЯ2»',
        tooltip_gen_text:'Дизельный генератор «Вепрь АДС 8-230 РЯ2» предназначен для использования в качестве аварийного и основного источника электроснабжения. Гарантирует бесперебойное обеспечение объекта электроэнергией в течение 21 часов без дозаправки.',
    }
}