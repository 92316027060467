import React, { useEffect, useState } from 'react';
import '../styles/header.css'
import { Link } from 'react-router-dom';
import logo1 from '../../media/header_logo.png'

const Header = (props)=>{
    const [links,setLinks]=useState([])
    useEffect(()=>{
        setLinks(props.navigation)
        if(!localStorage.getItem('locale')){
            localStorage.setItem('locale','ru-RU')
            if(document.getElementById('ru')){
                document.getElementById('ru').style.backgroundColor='#2A9F92'
                document.getElementById('ru').style.color='#FFFFFF'
                document.getElementById('en').style.backgroundColor='#FFFFFF'
                document.getElementById('en').style.color='#2A9F92'
            }
        }
        if(localStorage.getItem('locale')&&localStorage.getItem('locale')==='ru-RU'){
            document.getElementById('ru').style.backgroundColor='#2A9F92'
            document.getElementById('ru').style.color='#FFFFFF'
            document.getElementById('en').style.backgroundColor='#FFFFFF'
            document.getElementById('en').style.color='#2A9F92'
        }
        if(localStorage.getItem('locale')&&localStorage.getItem('locale')==='en-US'){
            document.getElementById('en').style.backgroundColor='#2A9F92'
            document.getElementById('en').style.color='#FFFFFF'
            document.getElementById('ru').style.backgroundColor='#FFFFFF'
            document.getElementById('ru').style.color='#2A9F92'
        }

    })
    const translate =(language)=>{
        localStorage.setItem('locale',language)
        window.location.reload()
    }
    return (
        <header className='header' id='top'>
            <div className='header-content'>
                <div className='header-logo'>
                    <Link Link to='/'><img src={logo1}/></Link>
                </div>
                <div className='header-nav-lang'>
                    <nav className='header-nav'>
                        {links&&links.map((item)=>
                           <Link to={'/'+Object.keys(item)[0]} key={Object.keys(item)[0]}>{item[Object.keys(item)[0]]}</Link> 
                        )}
                    </nav>
                    <div className='header-lang language'>
                        <div className='language-div'>
                            <div className='language-div-center'>
                                <div className='language-ru' id='ru' onClick={()=>translate('ru-RU')}>
                                    РУ
                                </div>
                                <div className='language-ru' id='en' onClick={()=>translate('en-US')}>
                                    EN
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        </header>
    );
}

export default Header;