import React from 'react';
import {FormattedMessage,useIntl} from 'react-intl';
import moment from 'moment-js';
import '../styles/contacts.css'
import Header from '../subcomponents/Header';
import nav from '../../media/nav.svg'
import phone from '../../media/phone.svg'
import message from '../../media/message.svg'
import image from '../../media/svgimage.svg'
import logo from '../../media/logox1.5.png'


const Contacts = () => {
    const intl = useIntl()
    const object = [{main:intl.formatMessage({id:'header_main'})},{about:intl.formatMessage({id:'header_about'})},{news:intl.formatMessage({id:'header_news'})},{contacts:intl.formatMessage({id:'header_contacts'})}]
    const info =(icon,text) =>{
        return(
            <div className='contacts-info'>
                <div className='contacts-info-icon'>
                    <img src={icon} alt='иконка чего-то'/>
                </div>
                <div className='contacts-info-text'>
                    {text}
                </div>
            </div>
        )
    }
    return (
        <div className='contacts-page'>
            <Header navigation={object}/>
            <div className='contacts-content'>
                <div className='top-gradient'>
                    <div className='left-gradient'>
                        <div className='contact-text'>
                            <div className='contact-text-title'>
                                <FormattedMessage id = "contacts_us"/>
                            </div>
                            <div className='contact-text-subtitle'>
                                <FormattedMessage id = "contacts_ask"/>
                            </div>
                            <div className='footer-contacts-info'>
                                {info(nav,intl.formatMessage({id:'footer_address'}))}
                                {info(phone,'+7 843 425 55 55')}
                                {info(message,'info@tattelemed.ru')}
                            </div>
                        </div>
                        <div className='contact-image'>
                            <img src={image}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='cont-footer'>
                <div className='cont-footer-content'>
                    <div className='cont-footer-logo'>
                        <img src={logo}/>
                    </div>
                    <div className='cont-footer-text'>
                        <div className='cont-footer-text-tattelemed'>
                            © {moment().format("YYYY")} <FormattedMessage id = "footer_ttm"/>
                        </div>
                        <div className='cont-footer-text-vector'>
                            © <FormattedMessage id = "footer_vector"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contacts;