import React from 'react';
import patent from '../../media/patent.svg'
import dot from '../../media/advdot.svg'

const Adv = (props) => {
    return (
        <div className='advan'>
            <div className='adv-dot'>
                <img src={props.icon=='dot'?dot:patent}/>
            </div>
            <div className='advant-text'>
                {props.text}
            </div>
        </div>
        );
}

export default Adv;