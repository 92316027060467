import { BrowserRouter } from "react-router-dom";
import AppRouter from "./component/AppRouter";
import {IntlProvider} from 'react-intl';
import { LOCALES } from "./i18n/locales";
import { messages } from "./i18n/messages";
import './App.css';
import { useEffect, useState } from "react";


function App() {
  const [locale,setLocale] = useState(LOCALES.RUSSIAN)
  useEffect(()=>{
    if(localStorage.getItem('locale')===null)localStorage.setItem('locale','ru-RU');
    else setLocale(localStorage.getItem('locale'));
  },[])
  return (
    <div className="App">
      <BrowserRouter>
        <IntlProvider messages={messages[locale]} locale={locale} defaultLocale={LOCALES.RUSSIAN}>
          <AppRouter/>
        </IntlProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
