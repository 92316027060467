import CircularProgress from '@mui/material/CircularProgress';
import React, { useCallback, useEffect, useState } from 'react';
import imageUrlBuilder from '@sanity/image-url'
import { PhotoProvider, PhotoView  } from 'react-photo-view';
import ReactPlayer from 'react-player'
import Footer from '../subcomponents/Footer';
import { Link, useParams } from 'react-router-dom';
import { client } from '../../client';
import '../styles/singlenew.css'
import moment from 'moment-js';

import Header from './Header';

const SingleNews = () => {
    const object = [{main:"ГЛАВНАЯ"},{about:"О ПРЕДПРИЯТИИ"},{news:"НОВОСТИ"},{contacts:"КОНТАКТЫ"}]
    const [info,setInfo] = useState([])
    const [moreNews,setMoreNews] = useState([])
    const [loading,setLoading] = useState(true)
    const url = window.location.pathname.split('/').pop();
    const urlFor = (source) => builder.image(source)
    let {slug} = useParams()
    const path = window.location.pathname.split('/')[window.location.pathname.split('/').length-1]
    const builder = imageUrlBuilder({
        ...client.config(),
        baseUrl:'https://cdn.sanity.io'
    })
    useEffect(()=>{
        setLoading(true)
        client.fetch(`*[slug.current=="${path}"]{
            title,
            body,
            publishedAt,
            mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            }
        }`).then(result=>{
            setInfo(result[0]);
        })
        client.fetch(`*[_type=="post"]{
            title,
            slug,
            publishedAt,
            mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            }
        }`).then(data=>{
            data.splice(0,1)
            data.splice(0,1)
            let without = data.filter((item)=>item.slug.current!==path)
            var randomNews = []
            let mas = []
            while(randomNews.length<3){
                randomNews[randomNews.length] = without.splice(Math.round(Math.floor()*without.length),1)
            }
            randomNews.map((item)=>mas.push(item[0]))
            setMoreNews(mas);
            setLoading(false)
        })
    },[slug])

    const returnTag =(block) => {
        let mas;
        if(block._type==='image'){
            return(
            <div className='single-page-image' key={block.key}>
                <PhotoProvider>
                    <PhotoView src={urlFor(block).toString()}>
                        <img src ={urlFor(block).toString()} key={block.key}/>
                    </PhotoView>
                </PhotoProvider>
            </div>)
        }
        if(block.hasOwnProperty("level"))return<p className='single-page-text-list' key={block.key}>•&nbsp;&nbsp;&nbsp;&nbsp;{block.children[0].text}</p>
        
        return <p className='single-page-text' key={block.key}>{block.children.map((item)=>{
                    if(item.marks.length!==0){
                        mas = block.markDefs.filter((item1)=>
                            item1._key===item.marks[0]
                        )
                        return <a href={mas[0].href} target="_blank" key={block.key}>{item.text}</a>
                    }
                    if (item.text.indexOf("https")>=0){
                        return <div className='p-video'><ReactPlayer url={item.text} controls={true} muted={false}/></div>
                    }
                        
                    return item.text
                    }
                )}</p>
    }
    const ReadMoreCard = (title,date,image,slug) =>{
        return ( 
            <Link to={`/news/${slug}`} className='read-more-card' key={slug}>
                <div className='read-more-image'>
                    {image&&<img src={image}/>}
                </div>
                <div className='read-more-text'>
                    {title&&title}
                </div>
                <div className='read-more-info'>
                    <div className='read-more-date'>
                        {date&&moment(date).format('DD/MM/YYYY')}
                    </div>
                    <div className='read-more-read'>Читать дальше</div>
                </div>
            </Link>
        )
    }
    return (
        <div className='single-news-page'>
            <Header navigation={object}/>
            {loading?
            <div className='loading'>
                <CircularProgress sx={{color:'#2A9F92'}} />
            </div>
            :
            <div className='single-background-image'>
                <div className='single-full-content'>
                    <div className='single-content'>
                        <div className='single-main-content'>
                            <div className='single-main-allnews'>
                                <Link to='/news' className='single-allnews-title'>
                                    Все новости
                                </Link>
                            </div>
                            <div className='single-main-card'>
                                <div className='main-card-image'>
                                    {
                                        info&&<img id='img' src={info.mainImage&&info.mainImage.asset.url}/>
                                    }
                                </div>
                                <div className='main-card-title'>
                                    {info&&info.title}
                                </div>
                                <div className='main-card-date'>
                                    {info&&moment(info.publishedAt).format("DD/MM/YYYY")}
                                </div>
                            </div>
                            <div className='single-page-body'>
                                {(info&&info.body)&&info.body.map((item)=>returnTag(item))}
                            </div>
                        </div>
                        <div className='single-secondary-content'>
                            <div className='read-more-block'>
                                <div className='read-more-title'>
                                    Читать похожее
                                </div>
                            </div>
                            {moreNews.length!==0&&
                                moreNews.map((item)=>ReadMoreCard(item.title,item.publishedAt,item.mainImage.asset.url,item.slug.current))
                            }
                        </div>
                    </div>
                </div>
            </div>
            }
            <Footer/>
            
        </div>
    );
}

export default SingleNews;