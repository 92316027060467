import PlayCircleFilledTwoToneIcon from '@mui/icons-material/PlayCircleFilledTwoTone';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { HashLink as Lnk } from 'react-router-hash-link';
import CardContent from '@mui/material/CardContent';
import 'react-photo-view/dist/react-photo-view.css';
import React, { useEffect,useState } from 'react';
import generator from '../../media/generator.png'
import {PhotoSlider } from 'react-photo-view';
import circle from '../../media/circle.png'
import { Link } from 'react-router-dom';
import logo from '../../media/logo.png';
import mat1 from '../../media/mat1.png';
import mat2 from '../../media/mat2.png';
import mat3 from '../../media/mat3.png';
import mat4 from '../../media/mat4.jpg';
import mat5 from '../../media/mat5.jpg';
import mat6 from '../../media/mat6.png';
import toser from '../../media/toser.svg';
import ant from '../../media/ant.png';
import fl from '../../media/fl.png';
import kdw from '../../media/kdw.png';
import mtmk from '../../media/mtmk.png';
import kamaz from '../../media/kamaz.png';
import mtmkprof from '../../media/mtmkprof.png';
import EastIcon from '@mui/icons-material/East';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import ReactPlayer from 'react-player'
import Advantage from '../subcomponents/Advantage';
import {FormattedMessage,useIntl} from 'react-intl';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import '../styles/main.css'
import PartnerCard from '../subcomponents/PartnerCard';
import Footer from '../subcomponents/Footer';
import {client} from '../../client';
import MainCard from '../subcomponents/MainCard';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

// import {Link} from 'react-router-dom'
const  Main = () => {
    const intl = useIntl()
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [visible, setVisible] = useState(false);
    const [index, setIndex] = useState(0);
    const [news,setNews] = useState([])
    const images=[mat1,mat2,mat3,mat4,mat5,mat6]
    const [showButton, setShowButton] = useState(false);
    const openSlider = (number) =>{
        setVisible(true);
        setIndex(number);
    }
    function sort_by_key(array, key){
        return array.sort(function(a, b){
            var x = a[key]; var y = b[key];
            return ((x < y) ? 1 : ((x > y) ? -1 : 0));
        });
    }
    useEffect(()=>{
        if(document.getElementById('main'))document.getElementById('main').addEventListener("scroll", () => {
            if (document.getElementById('main')&&document.getElementById('main').scrollTop > 960) {
                setShowButton(true);
            } 
            else {
                setShowButton(false);
            }
        });   
        if(localStorage.getItem('locale')&&localStorage.getItem('locale')==='ru-RU'&&document.getElementById('ru')){
            document.getElementById('ru').style.backgroundColor='#2A9F92'
            document.getElementById('ru').style.color='#FFFFFF'
            document.getElementById('en').style.backgroundColor='#FFFFFF'
            document.getElementById('en').style.color='#2A9F92'
        }
        if(localStorage.getItem('locale')&&localStorage.getItem('locale')==='en-US'&&document.getElementById('ru')){
            document.getElementById('en').style.backgroundColor='#2A9F92'
            document.getElementById('en').style.color='#FFFFFF'
            document.getElementById('ru').style.backgroundColor='#FFFFFF'
            document.getElementById('ru').style.color='#2A9F92'
        }
        if(!localStorage.getItem('locale')&&document.getElementById('ru')){
            localStorage.setItem('locale','ru-RU')
            document.getElementById('ru').style.backgroundColor='#2A9F92'
            document.getElementById('ru').style.color='#FFFFFF'
            document.getElementById('en').style.backgroundColor='#FFFFFF'
            document.getElementById('en').style.color='#2A9F92'
        }
        client.fetch(`*[_type=="post"]{
            title,
            slug,
            publishedAt,
            mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            }
        }`).then(data=>{
            let result = sort_by_key(data,'publishedAt')
            setNews(result.slice(0,3));
        })
    },[])
    const translate =(language)=>{
        localStorage.setItem('locale',language)
        window.location.reload()
    }
    const handleTooltipClose = (id) => {
      if(id===1)setOpen(false);
      if(id===2)setOpen2(false);
      if(id===3)setOpen3(false);
    };
    const handleTooltipOpen = (id) => {
        if(id===1)setOpen(true);
        if(id===2)setOpen2(true);
        if(id===3)setOpen3(true);
    };
    return (
        <div className='main' id='main'>
            {showButton && (
                <div className="back-to-top">
                    <a rel="noreferrer" href='#head'><ArrowUpwardOutlinedIcon sx={{fontSize:50,color:'white'}}/></a>
                </div>
            )}
            <div className='main-header' id='head'>
                <div className='image-header'>
                    <div className='image-header-gradient'>
                        <nav className='main-header-nav'>
                            <div className='main-logo'>
                                <img src={logo} alt='TatTeleMed'/>
                            </div>
                            <div className='main-header-link'>
                                <div className='link-text'>
                                    <Link to='/main'><FormattedMessage id = "header_main"/></Link>
                                </div>
                                <div className='link-text'>
                                    <Link to='/about'><FormattedMessage id = "header_about"/></Link>
                                </div>  
                                <div className='link-text'>
                                    <Link to='/news'><FormattedMessage id = "header_news"/></Link>
                                </div>  
                                <div className='link-text'>
                                    <Link to='/contacts'><FormattedMessage id = "header_contacts"/></Link>
                                </div>   
                            </div>
                        </nav>
                        <div className='main-header-content'>
                            <Fade left>
                                <div className='main-header-title'>
                                    <FormattedMessage id = "main_M"/><br/>
                                    <FormattedMessage id = "main_T"/><br/>
                                    <FormattedMessage id = "main_C"/><br/>
                                    <FormattedMessage id = "main_MTMC"/>
                                </div>
                                <Link to='/about' className='main-header-button'>
                                    <button to='/about'><FormattedMessage id = "main_details"/><EastIcon sx={{color:'#288289'}} fontSize="large"/></button>
                                </Link>
                                <div className='main-header-video'>
                                    <a className='video-div' href="#video">
                                        <PlayCircleFilledTwoToneIcon  fontSize='large' sx={{color:'#FFFFFF',fill:"white"}}/> 
                                        <div className='video-div-text'>
                                            <FormattedMessage id = "main_video"/>
                                        </div> 
                                    </a>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
                <div className='language'>
                    <div className='language-div'>
                        <div className='language-div-center'>
                            <div className='language-ru' id='ru' onClick={()=>translate('ru-RU')}>
                                РУ
                            </div>
                            <div className='language-ru' id='en' onClick={()=>translate('en-US')}>
                                EN
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mtmk'>
                    <img src={mtmk} alt='MTMK'/>
                </div>
            </div>
            <div className='main-mtmk'>
                <div className='mtmk-title'>
                    <FormattedMessage id = "main_m"/><br/>
                    <FormattedMessage id = "main_tc"/><br/>
                    <FormattedMessage id = "main_MTMC"/>
                </div>
                
                <div className='mtmk-image'>
                    <Fade>
                        <div className='image-with-buttons'>
                            <img src={mtmkprof} alt='MTMK'/>
                            <div className='plus1' onClick={()=>handleTooltipOpen(1)}>
                                <ClickAwayListener onClickAway={()=>handleTooltipClose(1)}>
                                    <Tooltip title={
                                            <Card sx={{ maxWidth: 345 }}>
                                                <CardActionArea>
                                                    <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'flex-start',padding:'20px 0px 0px 20px',}}>
                                                        <img src={fl} alt='asd' width='150' height='auto'/>
                                                    </div>
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div">
                                                        <FormattedMessage id = "tooltip_fl"/>
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        <FormattedMessage id = "tooltip_fl_text"/>
                                                    </Typography>
                                                </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        }
                                        placement="top"
                                        onClose={handleTooltipClose}
                                        open={open}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        componentsProps={{
                                            tooltip:{
                                                sx:{
                                                    border: '3px solid #18545B',
                                                    padding:'0',
                                                    boxShadow:'none',
                                                    borderRadius:'20px'
                                                }
                                            }
                                        }}
                                    >
                                        <div className='circle'>
                                            <img src={circle}/>
                                        </div>
                                    </Tooltip>
                                </ClickAwayListener>
                            </div>
                            <div className='plus2' onClick={()=>handleTooltipOpen(2)}>
                                <ClickAwayListener onClickAway={()=>handleTooltipClose(2)}>
                                    <Tooltip title={
                                            <Card sx={{ maxWidth: 345 }}>
                                                <CardActionArea>
                                                    <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'flex-start',padding:'20px 0px 0px 20px',}}>
                                                        <img src={ant} alt='asd' width='150' height='auto'/>
                                                    </div>
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div">
                                                        <FormattedMessage id = "tooltip_ant"/>
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        <FormattedMessage id = "tooltip_ant_text"/>
                                                    </Typography>
                                                </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        }
                                        placement="top"
                                        onClose={handleTooltipClose}
                                        open={open2}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        componentsProps={{
                                            tooltip:{
                                                sx:{
                                                    border: '3px solid #18545B',
                                                    padding:'0',
                                                    boxShadow:'none',
                                                    borderRadius:'20px'
                                                }
                                            }
                                        }}
                                    >
                                        <div className='circle'>
                                            <img src={circle}/>
                                        </div>
                                    </Tooltip>
                                </ClickAwayListener>
                            </div>
                            <div className='plus3' onClick={()=>handleTooltipOpen(3)}>
                                <ClickAwayListener onClickAway={()=>handleTooltipClose(3)}>
                                    <Tooltip title={
                                            <Card sx={{ maxWidth: 345 }}>
                                                <CardActionArea>
                                                    <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'flex-start',padding:'20px 0px 0px 20px',}}>
                                                        <img src={generator} alt='asd' width='150' height='auto'/>
                                                    </div>
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div">
                                                        <FormattedMessage id = "tooltip_gen"/>
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        <FormattedMessage id = "tooltip_gen_text"/>
                                                    </Typography>
                                                </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        }
                                        placement="top"
                                        onClose={handleTooltipClose}
                                        open={open3}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        componentsProps={{
                                            tooltip:{
                                                sx:{
                                                    border: '3px solid #18545B',
                                                    padding:'0',
                                                    boxShadow:'none',
                                                    borderRadius:'20px'
                                                }
                                            }
                                        }}
                                    >
                                        <div className='circle'>
                                            <img src={circle}/>
                                        </div>
                                    </Tooltip>
                                </ClickAwayListener>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
            <div className='main-about'>
                <Fade>
                    <div className='main-about-gradient'>
                        <div className='main-about-title'>
                            <FormattedMessage id = "main_about_title"/>
                        </div>
                        <div className='main-about-text'>
                            <FormattedMessage id = "main_about_text1"/>
                            <br/><br/><FormattedMessage id = "main_about_text2"/> 
                        </div>
                        <div className='main-about-image'>
                            <img src={kamaz}/>
                        </div>
                        <Lnk to='/about/#top' className='main-about-button'>
                            <button><FormattedMessage id = "main_details"/></button>
                        </Lnk>
                    </div>
                </Fade>
            </div>
            <div className='main-report' id='video'>
                <Fade>
                    <div className='main-report-title'>
                        <div className='main-report-title-text'>
                            <FormattedMessage id = "main_report_title"/>
                        </div>
                    </div>
                    <div className='main-report-content'>
                        <div className='main-report-content-grad'>
                            <div className='main-report-content-container'>
                                <div className='main-report-text'>
                                    <FormattedMessage id = "main_report_text"/>
                                </div>
                                <div className='main-report-video'>
                                    <ReactPlayer url='https://www.youtube.com/watch?v=fz5DyBtf_Sw'
                                    controls={true}
                                    muted={false}/> 
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
            <div className='main-advantages'>
                <Fade>
                    <div className='main-advantegs-title'>
                        <FormattedMessage id = "main_advantages_title"/>
                    </div>
                    <div className='advantages'>   
                        <Advantage id='1' text={intl.formatMessage({id:'main_advantages_experience'})}/>
                        <Advantage id='2' text={intl.formatMessage({id:'main_advantages_obsluzhivanie'})}/>
                        <Advantage id='3' text={intl.formatMessage({id:'main_advantages_uniq'})}/>
                        <Advantage id='4' text={intl.formatMessage({id:'main_advantages_reliability'})}/>
                        <div className='adv-gradient' id={'item5'}>
                            <div className='advantage-item' >
                                <div className='advantage-icon'>
                                    <img src={toser}/>
                                </div> 
                                <div className='advantage-text'>
                                    <p><FormattedMessage id = "main_advantages_toser1"/><a href='https://ru.wikipedia.org/wiki/ТОСЭРы_Татарстана' target='_blank'><FormattedMessage id = "main_advantages_toser2"/></a><FormattedMessage id = "main_advantages_toser3"/></p>
                                </div>           
                            </div>
                        </div>
                        <Advantage id='6' text={intl.formatMessage({id:'main_advantages_rutina'})}/>       
                    </div>
                </Fade>
            </div>
            <div className='main-news'>
                <div className='main-news-title'>
                    <FormattedMessage id = "main_news_title"/>
                </div>
                <Fade up>
                <div className='main-news-content'>
                    <div className='news-slider'>
                        {news&&news.map((item)=>
                            <MainCard key={item.slug.current} title={item.title} image={item.mainImage.asset.url} date = {item.publishedAt} read={intl.formatMessage({id:'main_read'})} slug={item.slug.current}/>
                        )}
                    </div>
                    <div className='news-slider-footer'>
                        <Lnk to='/news#top'className='news-slider-button'>
                            <button><FormattedMessage id = "main_news_allnews"/></button>
                        </Lnk>    
                    </div>
                </div>
                </Fade>
            </div>
            <div className='main-materials'>
                <div className='main-materials-title'>
                    <FormattedMessage id = "main_materials"/>
                </div>
                <Zoom>
                    <div className='main-materials-content'>
                        <div className='material-content'>
                            <PhotoSlider
                                images={images.map((item) => ({ src: item, key: item }))}
                                visible={visible}
                                onClose={() => setVisible(false)}
                                index={index}
                                onIndexChange={setIndex}
                            />
                            <div className='material material-1' onClick={() => openSlider(0)}>
                                <img src={require('../../media/mat1.png')}/>
                            </div>
                            <div className='material material-2' onClick={() => openSlider(1)}>
                                <img src={require('../../media/mat2.png')}/>
                            </div>
                            <div className='material material-3' onClick={() => openSlider(2)}>
                                <img src={require('../../media/mat3.png')}/>
                            </div>
                        </div>
                    </div>
                </Zoom>
            </div>
            <div className='main-partners'>
                <div className='main-partners-title'>
                    <FormattedMessage id = "main_partners"/>
                </div>
                <div className='partners-content'>
                    <div className='parnter-content-grid'>
                        <PartnerCard id='1' href='https://vector.ru/'/>
                        <PartnerCard id='2' href='https://ftizisbiomed.ru/'/>
                        <PartnerCard id='3' href='https://tng.ru/'/>
                        <PartnerCard id='4' href='https://kamaz.ru/'/>
                        <PartnerCard id='5' href='https://telemedai.ru/'/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
export default Main;