import React from 'react';
import { Route, Routes } from 'react-router-dom';
import About from './pages/About';
import Contacts from './pages/Contacts';
import Main from './pages/Main';
import News from './pages/News';
import NotFound from './pages/NotFound';
import './styles/main.css'
import SingleNews from './subcomponents/SingleNews';

const AppRouter = () => {
    return (
        <div className='container'>
            <Routes>
                <Route exact path='/' key='mains' element={<Main/>}/>
                <Route exact path='/main' key='Main' element={<Main/>}/>
                <Route exact path='/contacts' key='Contacts' element={<Contacts/>}/>
                <Route exact path='/about' key='About' element={<About/>}/>
                <Route exact path='/news' key='News' element={<News/>}/>
                <Route exact path='/news/:slug' key='slug' element={<SingleNews/>}/>
                <Route exact path='/*' key='notFoumd' element={<NotFound/>}/>
            </Routes>
        </div>
    );
};

export default AppRouter;