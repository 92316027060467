import React, { useEffect,useState,useRef } from 'react';
import { Link } from 'react-router-dom';
import '../styles/found.css'

const NotFound = () => {
    return (
        <div className='not-found'>
            <div className='not-found-content'>
                <div className='not-found-title'>
                    404
                </div>
                <div className='not-found-subtitle'>
                    Страница не найдена
                </div>
                <div className='not-found-text'>
                    <p>К сожалению запрашиваемая вами страница не найдена. Попробуйте изменить путь или вернитесь на <Link to='/main'>Главную</Link></p>
                </div>
            </div>
        </div>
    );
}

export default NotFound;